.player-page {
    padding: 0;
    height: 100%;
    overflow: hidden;
    display: flex;

    
    .main-content {
        padding: 3.4em 1em 1em 1em;
        flex-grow: 1;
        min-width: 0;
        .media-player, video, .vds-poster, [data-media-provider], .vds-controls {
            border: 0;
            border-radius: 0;
        }

        :where(.vds-live-button[data-edge] .vds-live-button-text),
        :where([data-live] .vds-time-slider .vds-slider-track-fill) {
            background-color: var(--player-live-color);
        }

        .player-wrapper {
            max-width: 1800px;
            margin: 0 auto;
        }

        video {
            background: url("//i.imgur.com/lKhS5pj.jpeg");
            background-size: contain;
        }

        .player-wait {
            position: relative;
            img {
                width: 100%;
            }
            .wait-message {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                text-align: center;
            }
        }
    }

    @media only screen and (max-width: 1000px) { 
        padding: 2.8em 0.5em 0.5em;
        display: block;
        .sidebar, .main-content {
            width: auto;
            min-width: 1px;
            padding: 0 0 1em 0;
            height: auto;
            overflow: normal;
            .sidebar-inner {
                height: auto;
                padding: 0;
            }
        }
    }
}

@media (max-height: 620px) {
    .player-page .main-content .player-wrapper {
       max-width: 645px;
       width: auto;
    }
}

.sidebar {
    width: 465px;
    min-width: 465px;
    overflow: none;
    height: 100%;
    padding: 3.4em 0 1em 0;
    box-sizing: border-box;

    h3 {
        background: var(--header-background-color);
        margin: 0;
        padding: 10px;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid var(--border-color);
    }

    .preview h3 {
        margin-top: 0;
    }

    .sidebar-inner {
        overflow: auto;
        height: 100%;
        padding-right: 1em;
    }

    .sidebar-item {
        padding: 15px 20px 15px 20px;
        .image {
            display: none; 
        }
        &.sidebar-item-player {
            padding-left: 80px;
            min-height: 60px;
            .image {
                display: block;
            }
        }
    
        border-right: 1px solid var(--border-color);
        border-left: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    
        font-size: 14px;
        font-weight: 300;
        position: relative;
    
        background: var(--border-clear-color);
        .image {
            background-color: var(--border-color);
            border-radius: 100px;
            width: 60px;
            height: 60px;
            position: absolute;
            left: 10px;
            top: 15px;
            border: 1px solid var(--border-color);
            background-size: contain;
        }
    }

    .tabs {
        border-bottom: 1px solid var(--border-color);
        overflow: hidden;
        margin-bottom: 1em;
        div {
            display: inline-block;
            padding: 10px;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid var(--border-color);
            position: relative;
            top: 5px;
            background: var(--border-clear-color);
            margin-right: 10px;
            cursor: pointer;
            outline: none;
            &.active, &:hover {
                background: var(--header-background-color);
                top: 1px;
                cursor: default;
                border-bottom: 0;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    
}
