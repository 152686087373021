.roster {
    padding-bottom: 1em;
    table {
        border-spacing: 0;
        border-collapse: separate;
        width: 100%;
        margin-bottom: 10px;

        border-right: 1px solid var(--border-color);
        border-left: 1px solid var(--border-color);

        &:last-child {
            margin-bottom: 0;
        }
    }

    tr {
        td, th {
            min-width: 20px;
            font-weight: 300;
            font-size: 11px;
            padding: 5px 3px;
            text-align: center;
            border-right: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            &:last-child {
                border-right: 0;
            }
            &:first-child {
                padding: 5px;
                width: 160px;
                text-align: left;
                background: #222;
            }
        }
        td.depth-1  {
            padding-left: 1.5em;
        }
        td.depth-2  {
            padding-left: 3em;
        }
        td.depth-3  {
            padding-left: 4.5em;
        }
        td.depth-4  {
            padding-left: 6em;
        }
        td.depth-5  {
            padding-left: 7.5em;
        }
        th {
            font-weight: 400;
            background: #222;
            text-transform: uppercase;
        }
    }
}