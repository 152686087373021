
h1 {
    padding: 10px 0 10px 0;
    font-weight: 400;
    margin: 0;
    font-size: 25px;
    @media only screen and (max-width: 600px) { 
        font-size: 18px;
    }
}
  
nav {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    padding: 0.5em 1em;
    width: 100%;
    background: var(--header-background-color);
    border-bottom: 1px solid var(--border-color);
    box-sizing: border-box;
    text-transform: capitalize;

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
  
    @media only screen and (max-width: 600px) { 
        position: relative;
        padding-left: 15px;
    }
  
    ul {
        position: relative;
        top: 2px;
        float: right;
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 14px;
        li {
            display: inline-block;
            padding-left: 0.5em;
        }
        @media only screen and (max-width: 500px) { 
            float: none;
            padding-top: 1em;
            li {
                padding: 0 1em 0 0;
            }
        }
    }
}