@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

:root {
  --text-color: #EEE;
  --link-hover-color: #FFF;
  --background-color: #000;
  --header-background-color: #333;
  --border-color: #555;
  --border-linescore-color: #999;
  --border-clear-color: #000;
  --header-live-background-color: #343;
  --header-planned-background-color: #334;
  --matchup-label: #CCC;
  --danger-color: #F00;
  --player-live-color: #22F;
}

html,
body,
#root {
  background: var(--background-color);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  min-height: 100%;
  height: 100%;

  @media only screen and (max-width: 1000px) { 
    min-height: 1px;
    height: auto;
  }
}

a, a:active, a:visited {
  color: var(--text-color);
}

a:hover {
  color: var(--link-hover-color);
}
